import React, { memo } from 'react'
import { ErrorMessage } from '@hookform/error-message'
import { Typography, FormHelperText } from '@material-ui/core'
import { useStyles } from './style'

export const InputLabel = memo(
  ({
    currentError,
    errors,
    field,
    name,
    inputSize,
    label,
    isPopBodyHasMaxHeight,
    isProfileImageError,
    isPopImageError,
    isVideoLinkError,
    isAudioLinkError,
  }) => {
    const classes = useStyles()

    return (
      <>
        {isPopBodyHasMaxHeight && (
          <Typography className={classes.error}>Text is too long!</Typography>
        )}
        {isProfileImageError && (
          <Typography className={classes.error}>
            Image extantion should be jpg, jpeg or png!
          </Typography>
        )}
        {isVideoLinkError && (
          <Typography className={classes.error}>Invalid video link</Typography>
        )}
        {isPopImageError && (
          <Typography className={classes.error}>
            Image extantion should be jpg, jpeg or png!
          </Typography>
        )}
        {isAudioLinkError && (
          <Typography className={classes.error}>Invalid audio link</Typography>
        )}
        <>
          {currentError && !field ? (
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ message }) => (
                <Typography className={classes.error}>{message}</Typography>
              )}
            />
          ) : (
            !isPopBodyHasMaxHeight &&
            !isProfileImageError &&
            !isPopImageError && (
              <FormHelperText>
                {label}
                {field && inputSize
                  ? `(${inputSize -
                      field.length} from ${inputSize} symbols symbols left)`
                  : ''}
              </FormHelperText>
            )
          )}
        </>
      </>
    )
  }
)
