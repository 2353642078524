import React, { useState, useMemo } from 'react'
import { CustomButton, getModalStyle, LabelWrapper, useStyles } from './style'
import { Box, Modal, Typography, Divider } from '@material-ui/core'
import { UsersListItem } from './UsersListItem'
import { navigate } from 'gatsby-link'
import { HeaderModalSearch } from './HeaderModalSearch'
import { ErrorMessage } from '@hookform/error-message'
import { UsersTags } from './UsersTags'
import {
  handleMultipleUsersIds,
  handleSingleUserIds,
  handleSelectAll,
} from './utils'
import { useEffect } from 'react'
import Add from '@material-ui/icons/Add'

export const UsersSelectionBlock = ({
  users,
  errors,
  setCheckedIds,
  checkedIds,
  name,
  title,
  titleButton,
  isSingleMode,
}) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle)
  const [searchValue, setSearchValue] = useState('')
  const [open, setOpen] = useState(false)
  const [checkedUsers, setCheckedUsers] = useState(checkedIds)

  const handleCheckedUsers = uid => {
    if (!isSingleMode) {
      setCheckedUsers(handleMultipleUsersIds(checkedUsers, uid))
    } else {
      setCheckedUsers(handleSingleUserIds(checkedUsers, uid))
    }
  }
  const handleOpen = () => {
    setCheckedUsers(checkedIds)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setSearchValue('')
  }
  const navigateToUsersPage = () => {
    navigate('/users/')
  }
  const handleSubmitUsers = () => {
    setSearchValue('')
    setCheckedIds(checkedUsers)
    setOpen(false)
  }
  useEffect(() => {
    setCheckedUsers(checkedIds)
  }, [checkedIds])

  const searchResults = useMemo(() => {
    if (searchValue) {
      return users.filter(user => {
        const uidMatch = user.uid
          .toLowerCase()
          .includes(searchValue.toLowerCase())

        const firstNameMatch =
          user.first_name &&
          user.first_name.toLowerCase().includes(searchValue.toLowerCase())

        const lastNameMatch =
          user.last_name &&
          user.last_name.toLowerCase().includes(searchValue.toLowerCase())

        return uidMatch || firstNameMatch || lastNameMatch
      })
    }

    return []
  }, [searchValue])

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <LabelWrapper>
        {users.length === 0 ? (
          <>
            <Typography>
              THIS RETAIL HAS NO USERS, PLEASE CREATE USERS
            </Typography>
            <CustomButton
              width="max-content"
              background="#3a8a77"
              type="button"
              onClick={navigateToUsersPage}
            >
              Create Users
            </CustomButton>
          </>
        ) : (
          <>
            <Typography variant="h5" style={{ color: 'gray' }}>
              {title}
            </Typography>
            <CustomButton
              type="button"
              width="max-content"
              onClick={handleOpen}
              endIcon={<Add />}
            >
              {titleButton}
            </CustomButton>
          </>
        )}
      </LabelWrapper>

      <Modal
        style={{ alignItems: 'center', justifyContent: 'center' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <HeaderModalSearch
            users={users}
            checkedUsers={checkedUsers}
            setCheckedUsers={setCheckedUsers}
            setSearchValue={setSearchValue}
            searchValue={searchValue}
            handleSelectAll={handleSelectAll}
            handleClose={handleClose}
            isSingleMode={isSingleMode}
          />
          <Box
            style={{
              dispaly: 'flex',
              flexDirection: 'column',
              overflowX: 'auto',
              borderRadius: '15px',
              background: 'white',
            }}
          >
            {searchResults.length !== 0 ? (
              <>
                {searchResults.map((user, i) => {
                  return (
                    <UsersListItem
                      key={i}
                      user={user}
                      handleCheckedUsers={handleCheckedUsers}
                      checkedUsers={checkedUsers}
                    />
                  )
                })}
              </>
            ) : (
              <>
                {users.map((user, i) => {
                  return (
                    <UsersListItem
                      key={i}
                      user={user}
                      handleCheckedUsers={handleCheckedUsers}
                      checkedUsers={checkedUsers}
                    />
                  )
                })}
              </>
            )}
          </Box>
          <CustomButton
            width="max-content"
            style={{
              marginTop: '20px',
              borderRadius: '20px',
              minWidth: '100px',
              alignSelf: 'end',
            }}
            onClick={() => handleSubmitUsers()}
          >
            Select
          </CustomButton>
        </div>
      </Modal>

      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography className={classes.error}>{message}</Typography>
        )}
      />
      {users.length !== 0 && (
        <UsersTags
          users={users}
          checkedIds={checkedIds}
          setCheckedIds={setCheckedIds}
        />
      )}
    </Box>
  )
}
