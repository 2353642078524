import React from 'react'
import { Box } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'

export const FbGridVideoPreview = ({ src }) => (
  <Box
    position="relative"
    display="flex"
    justifyContent="center"
    width="100%"
    height="100%"
    style={{ cursor: 'pointer' }}
  >
    <Box
      position="absolute"
      zIndex={2}
      top="50%"
      color="white"
      transform="translate(-50%, -50%)"
    >
      <PlayCircleOutlineIcon />
    </Box>
    <video
      src={src}
      className="fb-video"
      style={{ width: '100%', height: 'auto' }}
    />
  </Box>
)
