import React, { memo, useMemo, useState } from 'react'
import { Box, Button, IconButton, Modal, Typography } from '@material-ui/core'
import FsLightbox from 'fslightbox-react'
import Keys from '../../utils/Keys'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ImageIcon from '@material-ui/icons/Image'
import CreateIcon from '@material-ui/icons/Create'

export const getFileExtension = fileName => fileName?.split('.').pop() || ''

const MediaPostModal = ({ isOpen, onClose, children }) => (
  <Modal open={isOpen} onClose={onClose}>
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="20px"
      bgcolor="white"
    >
      {children}
    </Box>
  </Modal>
)

const ButtonBoxWrapper = props => (
  <Box display="flex" flexDirection="column" alignItems="center" {...props} />
)
const ButtonBox = props => (
  <Box display="flex" justifyContent="space-between" {...props} />
)
const PostDeleteButton = props => (
  <IconButton {...props}>
    <HighlightOffIcon size="25px" />
  </IconButton>
)

export const PostMediaGrid = memo(
  ({
    media,
    onDeleteMedia,
    deleteAll,
    addMedia,
    children,
    isEditable = false,
    isPopImage = false,
  }) => {
    const [isOpenLightBox, setIsOpenLightBox] = useState(false)
    const [isOpenMediaModal, setIsOpenMediaModal] = useState(false)
    const [currentImageIdx, setCurrentImageIdx] = useState(0)

    const openImageModalHandler = imageIdx => {
      console.log('openImageModalHandler', imageIdx)
      setCurrentImageIdx(imageIdx)
      setIsOpenLightBox(!isOpenLightBox)
    }

    const deleteMediaHandler = idx => {
      if (!onDeleteMedia) return
      onDeleteMedia(idx)
    }

    const mediaTypes = useMemo(
      () =>
        media?.map(el => {
          const fileExt = getFileExtension(el)
          return Keys.SUPPORTED_VIDEO_EXTANTIONS.includes(fileExt)
            ? 'video'
            : 'image'
        }),
      [media]
    )

    return (
      <>
        <ButtonBoxWrapper marginX="auto" marginTop="5px" marginBottom="10px">
          {isEditable && (
            <ButtonBox className="postModal__ButtonBox">
              <>
                {!isPopImage && (
                  <>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={addMedia}
                    >
                      <ImageIcon />
                      <Typography marginX="8px">Add Photos/Videos</Typography>
                    </Button>
                    <Button
                      variant="contained"
                      size="medium"
                      onClick={() => setIsOpenMediaModal(true)}
                    >
                      <CreateIcon />
                      <Typography marginX="8px">Edit</Typography>
                    </Button>
                  </>
                )}
                <Button variant="contained" onClick={deleteAll}>
                  <HighlightOffIcon size="25px" />
                  <Typography marginX="8px">Delete</Typography>
                </Button>
              </>
            </ButtonBox>
          )}
          <Box width="100%">
            {React.Children.map(children, (child, idx) => (
              <Box
                position="relative"
                width="100%"
                overflow="hidden"
                key={idx}
                onClick={() => openImageModalHandler(idx)}
              >
                {React.isValidElement(child) && child.type === 'video'
                  ? React.cloneElement(child, { controls: true })
                  : child}
              </Box>
            ))}
          </Box>
        </ButtonBoxWrapper>
        <MediaPostModal
          isOpen={isOpenMediaModal}
          onClose={() => setIsOpenMediaModal(false)}
        >
          {React.Children.map(children, (child, idx) => (
            <Box
              key={idx}
              width="100%"
              marginBottom="10px"
              maxHeight="300px"
              position="relative"
              overflow="hidden"
            >
              <PostDeleteButton
                onClick={() => deleteMediaHandler(idx)}
                tabIndex={0}
                role="button"
              />
              {React.isValidElement(child) && child.type === 'video'
                ? React.cloneElement(child, { controls: true })
                : child}
            </Box>
          ))}
        </MediaPostModal>
        <FsLightbox
          key={JSON.stringify(media)}
          toggler={isOpenLightBox}
          sources={media}
          sourceIndex={currentImageIdx}
          types={mediaTypes}
        />
      </>
    )
  }
)
