import React from 'react'
import { connect } from 'redux-zero/react/index'
import actions from '../../store/actions'
import { getRetailUsers, getUsersDataIds } from '../../services/api'

const withRetailUsers = WrappedComponent => {
  class WithRetailUsers extends React.Component {
    handleResponse = response => {
      if (response.status === 401) {
        this.props.logout()
      } else if (response.status === 200) {
        this.props.setUsers([...response.users])
        this.props.set
      }
    }
    handleUsersDataIdsResponse = response => {
      if (response.status === 401) {
        this.props.logout()
      } else if (response.status === 200) {
        this.props.setUsersDataIds(response)
      }
    }

    updateRetailUsers = () => {
      this.props.showFullScreenLoader()
      this.props.setNotificationSelectedUsers([])
      getRetailUsers(
        this.handleResponse,
        this.props.selected_rid,
        this.props.hideFullScreenLoader
      )
      getUsersDataIds(
        this.handleUsersDataIdsResponse,
        this.props.selected_rid,
        this.props.hideFullScreenLoader
      )
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.selected_rid !== this.props.selected_rid) {
        this.updateRetailUsers()
      }
    }

    componentDidMount() {
      if (this.props.selected_rid !== null) {
        this.updateRetailUsers()
      }
    }

    findUser = uid => {
      let _user = null
      this.props.users.forEach(user => {
        if (uid === user['uid']) {
          _user = user
        }
      })
      return _user
    }

    render() {
      return <WrappedComponent findUser={this.findUser} {...this.props} />
    }
  }
  const mapStateToProps = ({
    showDeleteUserWarningModal,
    showUserModal,
    selected_rid,
    loading,
    selectedUser,
    editUser,
    userAreas,
    userRidsDetails,
    openStoreSelector,
    posts,
    users,
    notificationSelectedUsers,
  }) => ({
    showDeleteUserWarningModal,
    showUserModal,
    selected_rid,
    loading,
    selectedUser,
    editUser,
    userAreas,
    userRidsDetails,
    openStoreSelector,
    posts,
    users,
    notificationSelectedUsers,
  })

  return connect(mapStateToProps, actions)(WithRetailUsers)
}

export default withRetailUsers
