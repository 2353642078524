import React from 'react'
import Layout from '../components/layout'
import withGroups from '../components/Elements/withGroups'
import withRetailUsers from '../components/Elements/withRetailUsers'
import PopCreation from '../components/Pop'
import FullScreenLoader from '../components/FullScreenLoader'

const PopPage = ({ loading }) => {
  return (
    <Layout>
      <PopCreation />
      <FullScreenLoader loading={loading} />
    </Layout>
  )
}

export default withGroups(withRetailUsers(PopPage))
