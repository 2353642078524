import React from 'react'
import { Avatar, Box, Button } from '@material-ui/core'
import StoreeDoll from '../../assets/doll_icon.png'

export const ChatGPTPopBody = ({ popBody }) => {
  return (
    <Box
      mx="auto"
      mt="25px"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: '10px',
        width: '90%',
        minHeight: '300px',
        background: 'white',
        textAlign: 'center',
        padding: '15px',
      }}
    >
      <Box
        display={'flex'}
        justifyContent={'flex-start'}
        mx="10px"
        mb="10px"
        maxWidth={'70%'}
      >
        <Box
          display={'flex'}
          flexDirection={'row'}
          width="100%"
          style={{ columnGap: '15px' }}
        >
          <Avatar
            w="4rem"
            h="3.4rem"
            background="transparent"
            src={StoreeDoll}
          />
          <Box
            display={'flex'}
            flexDirection={'column'}
            mt="20px"
            minWidth="200px"
            style={{
              borderBottomRightRadius: '20px',
              borderBottomLeftRadius: '20px',
              borderTopLeftRadius: '0',
              borderTopRightRadius: '20px',
              background: '#ffe6f2',
            }}
            p="1rem"
          >
            <div
              id="html_text"
              className="pop_text"
              dir="auto"
              style={{
                textOverflow: 'ellipsis',
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
                fontFamily: 'ArialMT',
                lineHeight: '20px',
                fontSize: '17px',
                '-webkit-line-clamp': 7,
                lineClamp: 7,
                // ' -webkit-box-orient': 'vertical',
              }}
            >
              {popBody}
            </div>
            {popBody && (
              <Box display={'flex'} justifyContent="space-between" mt="1rem">
                <Button style={{ background: 'white', minWidth: '48%' }}>
                  yes
                </Button>
                <Button style={{ background: 'white', minWidth: '48%' }}>
                  no
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
