import React, { useState, useRef, useEffect } from 'react'
import EmojiPicker from 'emoji-picker-react'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  emojiBox: {
    cursor: 'pointer',
    fontSize: '1.5rem',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.3)',
    },
  },
  blurredBox: {
    backdropFilter: 'blur(8px)',
    backgroundColor: '#e8e8e8',
    padding: '5px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
  },
})

export const ReactionsPicker = ({ onEmojiSelect, fieldName }) => {
  const classes = useStyles()
  const [showFullPicker, setShowFullPicker] = useState(false)
  const reactionEmojis = ['😀', '😂', '😍', '😢', '😡', '👍']
  const pickerRef = useRef(null)

  const handleEmojiClick = emojiObject => {
    onEmojiSelect(emojiObject.emoji, fieldName)
  }

  const handleReactionClick = emoji => {
    onEmojiSelect(emoji, fieldName)
  }
  const handleClickOutside = event => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setShowFullPicker(false)
    }
  }

  useEffect(() => {
    if (showFullPicker) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showFullPicker])

  return (
    <Box ref={pickerRef}>
      {!showFullPicker && (
        <Box
          className={classes.blurredBox}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
          borderRadius={'20px'}
          fontSize={'25px'}
          lineHeight={'normal'}
          style={{ columnGap: '10px' }}
        >
          {reactionEmojis.map(emoji => (
            <Box
              key={emoji}
              className={classes.emojiBox}
              style={{ border: 'none', background: 'none' }}
              onClick={() => handleReactionClick(emoji)}
            >
              {emoji}
            </Box>
          ))}
          <Box
            className={classes.emojiBox}
            style={{ fontSize: '15px' }}
            onClick={() => setShowFullPicker(!showFullPicker)}
          >
            ➕
          </Box>
        </Box>
      )}

      {showFullPicker && (
        <EmojiPicker
          onEmojiClick={handleEmojiClick}
          style={{ zIndex: 999999 }}
        />
      )}
    </Box>
  )
}
