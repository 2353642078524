import styled from 'styled-components'
import { TextField, FormHelperText, Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

export const CreatePopWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

export const PopCreationWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '50%',
  padding: '2%',
})

export const PopViewWrapper = styled('div')({
  width: '50%',
  padding: '2%',
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
})
export const CustomPopTextField = styled(TextField)({
  width: '60%',
  '&& ::before': {
    border: '1px solid #e8e8e8',
    height: '35px',
    borderRadius: '5px',
  },
  '&& ::after': {
    borderBottom: 'none',
  },
  '&& ::hover': {
    borderBottom: 'none',
  },
})

export const PopFormHelperText = styled(FormHelperText)({})

export const Row = styled(Box)({
  marginBottom: '30px',
})

export const LabelWrapper = styled(Box)({
  marginTop: ({ marginTop }) => marginTop,
  marginBottom: ({ marginBottom }) => marginBottom || 'none',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: ({ width }) => width || '100%',
  justifyContent: ({ justifyContent }) => justifyContent || 'space-between',
})

export const CustomButton = styled(Button)`
  && {
    background-color: ${({ background }) => background || '#6b7de2'};
    color: white;
    cursor: pointer;
    width: ${({ width }) => width || '140px'};
    text-align: center;
    border-radius: 15px;
    transition: all 0.3s;
    padding-left: 10px;
    padding-right: 10px;
  }
  &&:hover {
    background-color: ${({ background }) => background || '#6b7de2'};
    transform: scale(1.05);
    box-shadow: 0 5px 10px rgba(43, 42, 68, 0.29);
  }
  &&:active {
    box-shadow: none;
  }
  &&:focus {
    box-shadow: none;
    outline: none;
  }
`

export const getModalStyle = () => {
  const top = 10
  const left = 40

  return {
    top: `${top}%`,
    left: `${left}%`,
    margin: 'auto',
  }
}

export const useStyles = makeStyles(
  () => ({
    paper: {
      position: 'absolute',
      width: '500px',
      maxHeight: 'calc(100vh - 11rem)',
      backgroundColor: '#fff',
      border: '1px solid gray',
      borderRadius: '20px',
      paddingTop: '0px',
      paddingRight: '32px',
      paddingBottom: '24px',
      paddingLeft: '32px',
      display: 'flex',
      flexDirection: 'column',
    },
    menuBtn: {
      borderRadius: '10px',
      border: '1px solid #a2a6a2',
      width: '150px',
      borderColor: '#e8e8e8',
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: '#137cbd',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    },
    smallAvatar: {
      marginLeft: '5px',
      // width: theme.spacing(3),
      // height: theme.spacing(3),
    },
    error: {
      marginTop: '5px',
      color: 'red',
      fontSize: '13px',
    },
    inputWrapper: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    inputUnderline: {
      '&:hover:not($disabled):not($error):not($focused):before': {
        borderBottom: 'none',
      },
    },
    btn_submit: {
      background: '#75b789',
      color: 'white',
      cursor: 'pointer',
      width: '140px',
      textAlign: 'center',
      borderRadius: '15px',
    },
    input: {
      padding: '10px',
    },
    textField_pop: {
      width: '60%',
      border: '1px solid #e8e8e8',
      height: '35px',
      borderRadius: '5px',
      '&& ::before': {
        borderBottom: 'none',
      },
      '&& :after': {
        borderBottom: 'none',
      },
      '&& :hover': {
        borderBottom: 'none',
      },
      '&& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        content: 'none',
      },
    },
    textArea_pop: {
      width: '60%',
      height: '70px',
      resize: 'none',
      borderRadius: '10px',
      borderColor: '#e8e8e8',
      padding: '10px',
      '&:focus-visible': {
        outline: 'none',
      },
      '&.textarea:focus-visible': {
        outline: 'none',
      },
    },
    iconButton_pop: {
      'button:focus ~ &': {
        outline: 'none !important',
      },
      '&:focus-visible': {
        outline: 'none',
      },
      cursor: 'pointer',
      fill: 'gray',
      marginLeft: '10px',
      marginBottom: '2px',
      fontSize: 'medium',
    },
    search_pop: {
      width: '100%',
      border: '1px solid #e8e8e8',
      height: '40px',
      marginBottom: '20px',
      marginTop: '20px',
      borderRadius: '10px',
      background: '#F8F8F9 !important',
      '&& ::before': {
        borderBottom: 'none',
      },
      '&& :after': {
        borderBottom: 'none',
      },
      '&& :hover': {
        borderBottom: 'none',
      },
      '&& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 'none',
      },
    },
    search_pop_wrapper: {
      textAlign: 'center',
      width: '433px',
      background: 'white',
      zIndex: '100',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: '#fff',
    },
    footer_modal_pop: {
      position: 'fixed',
      background: 'white',
      width: '476px',
      height: '61px',
      zIndex: '100',
      top: 'calc(80% - 12rem)',
    },
    sectionWrapper: {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: { color: 'gray' },
    divider: {
      marginTop: '20px',
      marginBottom: '20px',
    },
    popBodyWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: '53px',
      width: '380px',
      background: 'white',
      margin: '100px auto 20px auto',
      boxShadow: '0px 0px 20px #777777',
      textAlign: 'center',
      padding: '0 33px 0 33px',
    },
    popViewText: {
      marginTop: '15pt',
      wordWrap: 'break-word',
      width: '100%',
      maxWidth: '236pt',
    },
    avatar: {
      background: 'white',
      boxShadow: '0px -2px 14px -1px #777777',
      width: '100px',
      height: '100px',
      margin: '-10% auto 5%',
    },
    usersListWrapper: {
      display: 'flex',
      flexDirection: 'row',
      padding: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      columnGap: '10px',
    },
    tagsContainer: {
      display: 'flex',
      padding: '15px',
      flexWrap: 'wrap',
      border: '1px solid #e8e8e8',
      borderRadius: '5px',
      justifyContent: 'center',
      overflowX: 'auto',
      maxHeight: '600px',
      background: 'white',
      marginTop: '20px',
      minHeight: '102px',
      alignItems: 'center',
    },
    tag: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      background: '#e8e8e8',
      borderRadius: '10px',
      width: '100%',
      maxWidth: '200px',
      margin: '5px',
      minHeight: '40px',
      padding: '10px',
    },
    tagInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formControlLabel: {
      '& .MuiFormControlLabel-root': {
        margin: '0px',
      },
    },
  }),
  { index: 1 }
)
