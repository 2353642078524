import Keys from '../../utils/Keys'
export const POP_TYPES = {
  DEFAULT_POP: 1,
  RE_POP: 2,
  CHAT_GPT: 3,
  FAKE_REPOP: 4,
}
export const DEFAULT_FORM_VALUES = {
  pop_type: POP_TYPES.DEFAULT_POP,
  pop_title: '',
  pop_body: '',
  ack_btn_text: '',
  push_title: '',
  profile_image_url:
    'https://sales-media.checkout-apps.com/hoodies/users_avatars/sto-hoo_8c7bdebefef753bc.jpg',
  push_body: '',
  contexts_ids: [],
  repop_contexts_ids: [],
  tags_context: [],
  pop_image_url: '',
  video: '',
  audio: '',
  repop_main_btn: '',
  repop_sec_btn: '',
  repop_push_title: '',
  repop_push_body: '',
}

export const handleMultipleUsersIds = (checkedIds, uid) => {
  if (checkedIds.includes(uid)) {
    return checkedIds.filter(id => id !== uid)
  } else {
    return [...checkedIds, uid]
  }
}
export const handleSingleUserIds = (checkedIds, uid) => {
  if (checkedIds.includes(uid)) {
    return checkedIds.filter(id => id !== uid)
  }
  return [uid]
}

export const handleSelectAll = (users, setCheckedIds) => {
  const usersIds = users.map(({ uid }) => uid)
  setCheckedIds(prevState => {
    return prevState.length === users.length ? [] : usersIds
  })
}
export const deleteUser = (uid, setCheckedIds, checkedIds) => {
  setCheckedIds(checkedIds.filter(id => id !== uid))
}
export const validateImage = (image, setError) => {
  if (image !== '' && image !== null) {
    let extantion = image
      .split('.')
      .pop()
      .toLowerCase()
    if (extantion === 'png' || extantion === 'jpeg' || extantion === 'jpg') {
      setError(false)
      return true
    } else {
      setError(true)
      return false
    }
  }
  setError(false)
  return false
}

export const validateVideo = (video, setError) => {
  if (video !== '' && video !== null) {
    let extantion = video
      .split('.')
      .pop()
      .toLowerCase()
    if (Keys.SUPPORTED_VIDEO_EXTANTIONS.includes(extantion)) {
      setError(false)
      return true
    } else {
      setError(true)
      return false
    }
  }
  setError(false)
  return false
}

export const validateAudio = (audio, setError) => {
  if (audio !== '' && audio !== null) {
    let extantion = audio
      .split('.')
      .pop()
      .toLowerCase()
    if (Keys.SUPPORTED_AUDIO_EXTANTIONS.includes(extantion)) {
      setError(false)
      return true
    } else {
      setError(true)
      return false
    }
  }
  setError(false)
  return false
}
