import React, { useMemo } from 'react'
import { Box, Avatar, Typography } from '@material-ui/core'
import AvatarPlaceholder from '../../assets/svg-icons/avatar-placeholder.svg'
import { useStyles } from './style'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteUser } from './utils'

export const UsersTags = ({ users, checkedIds, setCheckedIds }) => {
  const classes = useStyles()

  const selectedUsers = useMemo(
    () => users?.filter(user => checkedIds?.includes(user.uid) && user),
    [users, checkedIds]
  )

  return (
    <>
      <Box gridGap="5" className={classes.tagsContainer}>
        {selectedUsers && selectedUsers.length > 0 ? (
          <>
            {selectedUsers.map((user, i) => {
              return (
                <Box className={classes.tag} key={i}>
                  <Box className={classes.tagInfo}>
                    <Avatar
                      className={classes.smallAvatar}
                      src={
                        user.profile_img_url
                          ? user.profile_img_url
                          : AvatarPlaceholder
                      }
                    />
                    <Typography
                      style={{ marginLeft: '20px', fontSize: '12px' }}
                    >
                      {user.uid}
                    </Typography>
                  </Box>

                  <DeleteIcon
                    onClick={() =>
                      deleteUser(user.uid, setCheckedIds, checkedIds)
                    }
                    className={classes.iconButton_pop}
                  />
                </Box>
              )
            })}
          </>
        ) : (
          <Typography style={{ color: 'gray' }}>
            No recipients selected
          </Typography>
        )}
      </Box>
    </>
  )
}
