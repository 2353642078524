import React, { useState, memo, useCallback, useEffect } from 'react'
import { CustomButton, getModalStyle, LabelWrapper, useStyles } from './style'
import {
  Box,
  Modal,
  Typography,
  Divider,
  Chip,
  Checkbox,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { ErrorMessage } from '@hookform/error-message'
import Add from '@material-ui/icons/Add'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import clsx from 'clsx'

const TagsList = memo(({ tags, handleDelete }) => {
  const classes = useStyles()
  return (
    <Box gridGap="5" className={classes.tagsContainer}>
      {tags && tags.length > 0 ? (
        <>
          {tags.map(tag => {
            return (
              <Chip
                key={tag.sk}
                label={tag.name}
                clickable
                color="primary"
                onDelete={() => handleDelete(tag.sk)}
                deleteIcon={<HighlightOffIcon />}
                style={{ margin: '5px' }}
              />
            )
          })}
        </>
      ) : (
        <Typography style={{ color: 'gray' }}>
          No recipients selected
        </Typography>
      )}
    </Box>
  )
})

const TagItem = memo(({ tag, handleSelectTags, isChecked }) => {
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.usersListWrapper}
        onClick={() => {
          handleSelectTags(tag.sk)
        }}
      >
        <Box className={classes.userInfo}>
          <Chip size="medium" label={tag.name} color="primary" />
        </Box>
        <Checkbox
          checked={isChecked}
          className={classes.root}
          disableRipple
          color="default"
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
        />
      </Box>
      <Divider />
    </>
  )
})

const TagsSelectionBlock = ({
  checkedTags,
  setCheckedTags,
  errors,
  name,
  retailTags,
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [modalStyle] = useState(getModalStyle)
  const [checkedItems, setIsCheckedItems] = useState([])

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectTags = useCallback(uid => {
    setIsCheckedItems(prev => {
      if (prev.includes(uid)) {
        return prev.filter(id => id !== uid)
      } else {
        return [...prev, uid]
      }
    })
  }, [])

  const handleSubmit = () => {
    setCheckedTags(checkedItems)
    setOpen(false)
  }
  const handleDelete = sk => {
    setCheckedTags(prev => prev.filter(id => id !== sk))
    setIsCheckedItems(prev => prev.filter(id => id !== sk))
  }
  useEffect(() => {
    setIsCheckedItems(checkedTags)
  }, [checkedTags])

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }} my="15px">
      <LabelWrapper>
        <>
          <Typography variant="h5" style={{ color: 'gray' }}>
            Specify Tags
          </Typography>
          <CustomButton
            type="button"
            width="max-content"
            onClick={handleOpen}
            endIcon={<Add />}
          >
            {'Add Tags'}
          </CustomButton>
        </>
      </LabelWrapper>
      <Modal
        style={{ alignItems: 'center', justifyContent: 'center' }}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          <Box style={{ width: '100%' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <IconButton
                onClick={() => handleClose()}
                aria-label="delete"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <CloseIcon fontSize="small" width="20px" />
              </IconButton>
              <Typography
                variant="h5"
                style={{
                  color: 'gray',
                  marginTop: '10px',
                  marginBottom: '10px',
                  marginLeft: '23%',
                }}
              >
                Select Tags
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box
            style={{
              dispaly: 'flex',
              flexDirection: 'column',
              overflowX: 'auto',
              borderRadius: '15px',
              background: 'white',
            }}
          >
            <>
              {retailTags.map((tag, i) => {
                return (
                  <TagItem
                    key={i}
                    tag={tag}
                    isChecked={checkedItems.includes(tag.sk)}
                    handleSelectTags={handleSelectTags}
                  />
                )
              })}
            </>
          </Box>
          <CustomButton
            width="max-content"
            style={{
              marginTop: '20px',
              borderRadius: '20px',
              minWidth: '100px',
              alignSelf: 'end',
            }}
            onClick={() => handleSubmit()}
          >
            Select
          </CustomButton>
        </div>
      </Modal>
      <ErrorMessage
        errors={errors}
        name={name}
        render={({ message }) => (
          <Typography className={classes.error}>{message}</Typography>
        )}
      />
      <TagsList
        handleDelete={handleDelete}
        tags={retailTags.filter(tag => checkedTags.includes(tag.sk))}
      />
    </Box>
  )
}

export default TagsSelectionBlock
