import React from 'react'
import { Box, Avatar, Typography, Divider } from '@material-ui/core'
import AvatarPlaceholder from '../../assets/svg-icons/avatar-placeholder.svg'
import { StyledCheckbox } from './CheckBox'
import { useStyles } from './style'

export const UsersListItem = ({ user, handleCheckedUsers, checkedUsers }) => {
  const { profile_img_url, uid, first_name, last_name } = user
  const classes = useStyles()
  return (
    <>
      <Box
        className={classes.usersListWrapper}
        onClick={() => {
          handleCheckedUsers(uid)
        }}
      >
        <Box className={classes.userInfo}>
          <Avatar src={profile_img_url ? profile_img_url : AvatarPlaceholder} />
          <Box>
            <Typography style={{ fontSize: '16px' }}>
              <span style={{ color: 'gray', fontSize: '14px' }}>
                userID:{` `}
              </span>
              {uid}
            </Typography>

            <Typography style={{ fontSize: '16px' }}>
              <span style={{ color: 'gray', fontSize: '14px' }}>
                Full name:{` `}
              </span>
              {first_name}
              {` `}
              {last_name}
            </Typography>
          </Box>
        </Box>
        <StyledCheckbox
          uid={uid}
          handleCheckedUsers={handleCheckedUsers}
          checkedUsers={checkedUsers}
        />
      </Box>
      <Divider />
    </>
  )
}
