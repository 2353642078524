import React, { memo } from 'react'
import { Checkbox } from '@material-ui/core'
import clsx from 'clsx'
import { useStyles } from './style'

export const StyledCheckbox = memo(
  ({ uid, handleCheckedUsers, checkedUsers }) => {
    const classes = useStyles()

    return (
      <Checkbox
        onChange={() => {
          handleCheckedUsers(uid)
        }}
        checked={checkedUsers?.includes(uid)}
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={
          <span className={clsx(classes.icon, classes.checkedIcon)} />
        }
        icon={<span className={classes.icon} />}
        inputProps={{ 'aria-label': 'decorative checkbox' }}
      />
    )
  }
)
