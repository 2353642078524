import { PopViewWrapper } from './style'
import React, { useMemo } from 'react'
import { Typography } from '@material-ui/core'
import { useEffect } from 'react'
import { POP_TYPES } from './utils'
import './styles.css'
import { connect } from 'redux-zero/react'
import { InfoPopBody } from './InfoPopBody'
import { ChatGPTPopBody } from './ChatGPTPopBody'

const PopView = ({
  watch,
  setPopBodyHasMaxHeight,
  setPopImageError,
  setProfileImageError,
  setIsRepopView,
  isRepopView,
  users,
  isCustomAvatar,
  setVideoLinkError,
  setAudioLinkError,
}) => {
  const [
    popTitle,
    popBody,
    ackBtn,
    profile_image_url,
    pop_image_url,
    video,
    audio,
    repop_sec_btn,
    repop_main_btn,
    pop_type,
    repop_contexts_ids,
  ] = watch([
    'pop_title',
    'pop_body',
    'ack_btn_text',
    'profile_image_url',
    'pop_image_url',
    'video',
    'audio',
    'repop_sec_btn',
    'repop_main_btn',
    'pop_type',
    'repop_contexts_ids',
  ])

  useEffect(() => {
    if (popBody) {
      const htmlTextHeight = document.getElementById('html_text')
      if (htmlTextHeight.clientHeight > 140) {
        setPopBodyHasMaxHeight(true)
      } else {
        setPopBodyHasMaxHeight(false)
      }
    }
  }, [popBody])

  const profileImageUrl = useMemo(() => {
    if (pop_type === POP_TYPES.FAKE_REPOP) {
      return ''
    }
    if (pop_type === POP_TYPES.DEFAULT_POP || isCustomAvatar) {
      return profile_image_url
    } else {
      const selectedRepopUser = users.find(
        user => user.uid === repop_contexts_ids?.[0]
      )

      return selectedRepopUser?.avatar_profile_img_url
        ? selectedRepopUser?.avatar_profile_img_url
        : selectedRepopUser?.profile_img_url
    }
  }, [repop_contexts_ids, pop_type, profile_image_url, isCustomAvatar])

  return (
    <PopViewWrapper>
      <Typography style={{ marginBottom: '15px', color: 'gray' }} variant="h5">
        Pop-box Preview
      </Typography>
      <div
        style={{
          position: 'relative',
          width: '100%',
          background: '#696969',
          height: '100%',
          borderRadius: '10px',
        }}
      >
        <>
          {pop_type === POP_TYPES.CHAT_GPT ? (
            <ChatGPTPopBody popBody={popBody} />
          ) : (
            <InfoPopBody
              popType={pop_type}
              isRepopView={isRepopView}
              setIsRepopView={setIsRepopView}
              profileImageUrl={profileImageUrl}
              setProfileImageError={setProfileImageError}
              popTitle={popTitle}
              popBody={popBody}
              pop_image_url={pop_image_url}
              ackBtn={ackBtn}
              repop_main_btn={repop_main_btn}
              repop_sec_btn={repop_sec_btn}
              pop_video_url={video}
              pop_audio_url={audio}
              setPopImageError={setPopImageError}
              setVideoLinkError={setVideoLinkError}
              setAudioLinkError={setAudioLinkError}
            />
          )}
        </>
      </div>
    </PopViewWrapper>
  )
}

const mapStateToProps = ({ users }) => ({
  users,
})
export default connect(mapStateToProps)(PopView)
