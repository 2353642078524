import React, { useEffect, useState } from 'react'
import { Row, useStyles } from './style'
import {
  TextareaAutosize,
  Box,
  TextField,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { InputLabel } from './InputLabel'
import Keys from '../../utils/Keys'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import { POP_TYPES } from './utils'
import { Switch, Typography, FormControlLabel } from '@material-ui/core'
import { ReactionsPicker } from '../Emojis/index'

export const PopBoxMessage = ({
  register,
  errors,
  watch,
  resetField,
  isPopBodyHasMaxHeight,
  isPopImageError,
  isProfileImageError,
  isVideoLinkError,
  isAudioLinkError,
  isCustomAvatar,
  setIsCustomAvatar,
  setValue,
  isSubmitted,
  selected_rid,
}) => {
  const [
    popTitle,
    ackButtonText,
    profile_image_url,
    pop_type,
    popBody,
  ] = watch([
    'pop_title',
    'ack_btn_text',
    'profile_image_url',
    'pop_type',
    'pop_body',
  ])
  const [radioValue, setRadioValue] = useState('pop_image_url')
  const [imageUrl, setImageUrl] = useState('')
  const [videoUrl, setVideoUrl] = useState('')
  const [audioUrl, setAudioUrl] = useState('')

  const classes = useStyles()

  const handleChange = event => {
    const field = event.target.value
    setRadioValue(field)
    if (field === 'pop_image_url') {
      setValue('pop_image_url', imageUrl)
      setValue('video', '')
      setValue('audio', '')
    }
    if (field === 'video') {
      setValue('video', videoUrl)
      setValue('audio', '')
      setValue('pop_image_url', '')
    }
    if (field === 'audio') {
      setValue('audio', audioUrl)
      setValue('video', '')
      setValue('pop_image_url', '')
    }
  }

  const [activeInput, setActiveInput] = useState({
    title: false,
    body: false,
    ackBtn: false,
  })
  const onEmojiSelect = (emoji, field) => {
    const element = document.getElementsByName(field)[0]
    const start = element.selectionStart
    const end = element.selectionEnd
    if (activeInput.title) {
      const newText =
        popTitle.substring(0, start) + emoji + popTitle.substring(end)
      setValue(field, newText)
    }
    if (activeInput.body) {
      const newText =
        popBody.substring(0, start) + emoji + popBody.substring(end)
      setValue(field, newText)
    }
    if (activeInput.ackBtn) {
      const newText =
        ackButtonText.substring(0, start) + emoji + ackButtonText.substring(end)
      setValue(field, newText)
    }
    return
  }

  useEffect(() => {
    setRadioValue('pop_image_url')
    setImageUrl('')
    setVideoUrl('')
    setAudioUrl('')
  }, [isSubmitted, selected_rid])

  return (
    <>
      {pop_type !== POP_TYPES.CHAT_GPT && (
        <>
          <Row>
            <Box
              display="flex"
              flexWrap={'wrap'}
              justifyContent={'space-between'}
            >
              <Box className={classes.inputWrapper} style={{ width: '60%' }}>
                <TextField
                  {...register('profile_image_url', {
                    required: 'This field is required   * ',
                  })}
                  disabled={
                    !isCustomAvatar &&
                    (pop_type === POP_TYPES.RE_POP ||
                      pop_type === POP_TYPES.FAKE_REPOP)
                  }
                  id="profile-img"
                  className={classes.textField_pop}
                  placeholder="Profile image url"
                  inputProps={{
                    className: classes.input,
                  }}
                  style={{ width: '100%' }}
                />
                <RotateLeftIcon
                  onClick={() => {
                    resetField('profile_image_url')
                  }}
                  disabled={pop_type === 2}
                  style={{ width: '25px', height: '25px' }}
                  className={classes.iconButton_pop}
                />
              </Box>
              {pop_type === POP_TYPES.RE_POP && (
                <Box display="flex" alignItems="center">
                  <Typography color="gray">Custom avatar image</Typography>
                  <Switch
                    checked={isCustomAvatar}
                    onChange={() => setIsCustomAvatar(prev => !prev)}
                  />
                </Box>
              )}
            </Box>

            <InputLabel
              isProfileImageError={isProfileImageError}
              currentError={errors.profile_image_url}
              errors={errors}
              field={profile_image_url}
              name="profile_image_url"
              label="Paste the profile image url (Image extantion should be jpg, jpeg or png)  * "
            />
          </Row>
          <Row>
            <Box className={classes.inputWrapper}>
              <TextField
                {...register('pop_title', {
                  required: 'This field is required   * ',
                })}
                className={classes.textField_pop}
                inputProps={{
                  className: classes.input,
                  maxLength: Keys.POP_TITLE_SIZE,
                }}
                type="text"
                name="pop_title"
                placeholder="Title"
                onMouseDown={() => {
                  setActiveInput(prev => ({
                    ...prev,
                    title: !prev.title,
                    body: false,
                    ackBtn: false,
                  }))
                }}
              />
              <Box style={{ cursor: 'pointer' }} position={'relative'}>
                {activeInput.title && (
                  <Box position={'absolute'} top={'10px'}>
                    <ReactionsPicker
                      onEmojiSelect={onEmojiSelect}
                      fieldName="pop_title"
                    />
                  </Box>
                )}
              </Box>
              <DeleteIcon
                style={{ marginLeft: '15px' }}
                onClick={() => {
                  resetField('pop_title')
                }}
                className={classes.iconButton_pop}
              />
            </Box>
            <InputLabel
              currentError={errors.pop_title}
              errors={errors}
              field={popTitle}
              name="pop_title"
              inputSize={Keys.POP_TITLE_SIZE}
              label="Type Pop Title * "
            />
          </Row>
        </>
      )}

      <Row>
        <Box className={classes.inputWrapper}>
          <TextareaAutosize
            {...register('pop_body', {
              required: 'This field is required   * ',
            })}
            id="message"
            rowsMin={5}
            dir="auto"
            className={classes.textArea_pop}
            type="all"
            onMouseDown={() => {
              setActiveInput(prev => ({
                ...prev,
                body: !prev.body,
                title: false,
                ackBtn: false,
              }))
            }}
            placeholder="Message"
            inputProps={{
              className: classes.input,
            }}
          />
          <Box style={{ cursor: 'pointer' }} position={'relative'}>
            {activeInput.body && (
              <Box position={'absolute'} top={'10px'}>
                <ReactionsPicker
                  onEmojiSelect={onEmojiSelect}
                  fieldName="pop_body"
                />
              </Box>
            )}
          </Box>
        </Box>
        <InputLabel
          isPopBodyHasMaxHeight={isPopBodyHasMaxHeight}
          currentError={errors.pop_body}
          errors={errors}
          name="pop_body"
          label="Type Pop Message  * "
        />
      </Row>
      {pop_type !== POP_TYPES.CHAT_GPT && (
        <>
          <RadioGroup value={radioValue} onChange={handleChange}>
            <Box display="flex" style={{ width: '100%' }}>
              <FormControlLabel
                value="pop_image_url"
                control={<Radio />}
                style={{ margin: '0px' }}
              />
              <Box display="flex" flexDirection="column" width="100%">
                <Box className={classes.inputWrapper}>
                  <TextField
                    onInput={e => {
                      setImageUrl(e.target.value)
                      setValue('pop_image_url', e.target.value)
                    }}
                    style={{ width: '100%' }}
                    disabled={radioValue !== 'pop_image_url'}
                    className={classes.textField_pop}
                    placeholder="Pop image url"
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
                <InputLabel
                  isPopImageError={isPopImageError}
                  currentError={errors.pop_image_url}
                  errors={errors}
                  field="pop_image_url"
                  name="pop_image_url"
                  label="Paste pop image url"
                />
              </Box>
            </Box>
            <Box display="flex" style={{ width: '100%' }}>
              <FormControlLabel
                value="video"
                control={<Radio />}
                style={{ margin: '0px' }}
              />
              <Box display="flex" flexDirection="column" width="100%">
                <Box className={classes.inputWrapper}>
                  <TextField
                    onInput={e => {
                      setVideoUrl(e.target.value)
                      setValue('video', e.target.value)
                    }}
                    style={{ width: '100%' }}
                    disabled={radioValue !== 'video'}
                    className={classes.textField_pop}
                    placeholder="Pop video url"
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
                <InputLabel
                  isVideoLinkError={radioValue === 'video' && isVideoLinkError}
                  currentError={errors.video}
                  errors={errors}
                  field="video"
                  name="video"
                  label="Paste pop video url"
                />
              </Box>
            </Box>
            <Box display="flex" style={{ width: '100%' }}>
              <FormControlLabel
                value="audio"
                control={<Radio />}
                style={{ margin: '0px' }}
              />
              <Box display="flex" flexDirection="column" width="100%">
                <Box className={classes.inputWrapper}>
                  <TextField
                    onInput={e => {
                      setAudioUrl(e.target.value)
                      setValue('audio', e.target.value)
                    }}
                    disabled={radioValue !== 'audio'}
                    className={classes.textField_pop}
                    style={{ width: '100%' }}
                    placeholder="Pop audio url"
                    inputProps={{
                      className: classes.input,
                    }}
                  />
                </Box>
                <InputLabel
                  isAudioLinkError={radioValue === 'audio' && isAudioLinkError}
                  currentError={errors.audio}
                  errors={errors}
                  field="audio"
                  name="audio"
                  label="Paste pop audio url"
                  style={{ width: '600px' }}
                />
              </Box>
            </Box>
          </RadioGroup>
          {pop_type !== POP_TYPES.FAKE_REPOP && (
            <Row>
              <Box className={classes.inputWrapper}>
                <TextField
                  {...register('ack_btn_text', {
                    required: 'This field is required   * ',
                  })}
                  id="button-text"
                  className={classes.textField_pop}
                  placeholder="Text"
                  onMouseDown={() => {
                    setActiveInput(prev => ({
                      ...prev,
                      ackBtn: !prev.ackBtn,
                      body: false,
                      title: false,
                    }))
                  }}
                  inputProps={{
                    className: classes.input,
                    maxLength: Keys.POP_ACKNBUTTON_SIZE,
                  }}
                />
                <Box style={{ cursor: 'pointer' }} position={'relative'}>
                  {activeInput.ackBtn && (
                    <Box position={'absolute'} top={'10px'}>
                      <ReactionsPicker
                        onEmojiSelect={onEmojiSelect}
                        fieldName="ack_btn_text"
                      />
                    </Box>
                  )}
                </Box>
                <DeleteIcon
                  style={{ marginLeft: '15px' }}
                  onClick={() => {
                    resetField('ack_btn_text')
                  }}
                  className={classes.iconButton_pop}
                />
              </Box>
              <InputLabel
                currentError={errors.ack_btn_text}
                errors={errors}
                field={ackButtonText}
                name="ack_btn_text"
                inputSize={Keys.POP_ACKNBUTTON_SIZE}
                label="Type Pop button text  * "
              />
            </Row>
          )}
        </>
      )}
    </>
  )
}
