import React, { memo } from 'react'
import { UsersSelectionBlock } from './UsersSelectionBlock'
import { PopTypeDropdown } from './PopTypeDropdown'
import { PopCreationWrapper, Row, useStyles } from './style'
import { CustomButton } from './style'
import DeleteIcon from '@material-ui/icons/Delete'
import { InputLabel } from './InputLabel'
import Keys from '../../utils/Keys'
import {
  Typography,
  Divider,
  Box,
  CircularProgress,
  TextField,
  Switch,
} from '@material-ui/core'

import { PopNotification } from './PopNotification'
import { PopBoxMessage } from './PopBoxMessage'
import { POP_TYPES } from './utils'
import TagsSelectionBlock from './TagsSelectionBlock'

export const CreatePop = memo(
  ({
    watch,
    loading,
    register,
    errors,
    users,
    checkedIds,
    setCheckedIds,
    checkedRepopIds,
    setCheckedRepopIds,
    handleSubmit,
    resetField,
    checkedTags,
    setCheckedTags,
    setPopBodyHasMaxHeight,
    isPopBodyHasMaxHeight,
    isPopImageError,
    isProfileImageError,
    setValue,
    getValues,
    isPopPost,
    setIsPopPost,
    isCustomAvatar,
    setIsCustomAvatar,
    retailTags,
    isTimeLess,
    setIsTimeLess,
    isSubmitted,
    selected_rid,
    isVideoLinkError,
    isAudioLinkError,
    checkedDefaultPop,
    setCheckedDefaultPop,
    checkedRepop,
    setCheckedRepop,
  }) => {
    const classes = useStyles()
    const type = watch('pop_type')
    return (
      <PopCreationWrapper>
        <Row>
          <Box className={classes.sectionWrapper}>
            <Typography variant="h5" style={{ color: 'gray' }}>
              Create Pop-box message
            </Typography>
            {loading ? (
              <CircularProgress
                color="#a2a6a2"
                style={{ width: '30px', height: '30px' }}
              />
            ) : (
              <CustomButton form="pop_form" type="submit" background="#6b7d">
                Submit
              </CustomButton>
            )}
          </Box>
          <Divider style={{ marginTop: '10px' }} />
        </Row>

        <form id="pop_form" onSubmit={handleSubmit}>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Typography color="gray">Set Timeless POP</Typography>
            <Switch
              checked={isTimeLess}
              onChange={() => setIsTimeLess(prev => !prev)}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            mb="20px"
          >
            <PopTypeDropdown
              watch={watch}
              register={register}
              setValue={setValue}
            />
            {type === POP_TYPES.RE_POP && (
              <Box display="flex" alignItems="center">
                <Typography color="gray">Create pop as post</Typography>
                <Switch
                  checked={isPopPost}
                  onChange={() => setIsPopPost(prev => !prev)}
                />
              </Box>
            )}
          </Box>

          <PopBoxMessage
            watch={watch}
            register={register}
            errors={errors}
            resetField={resetField}
            isPopBodyHasMaxHeight={isPopBodyHasMaxHeight}
            setPopBodyHasMaxHeight={setPopBodyHasMaxHeight}
            isPopImageError={isPopImageError}
            isProfileImageError={isProfileImageError}
            isCustomAvatar={isCustomAvatar}
            setIsCustomAvatar={setIsCustomAvatar}
            getValues={getValues}
            setValue={setValue}
            isSubmitted={isSubmitted}
            selected_rid={selected_rid}
            isVideoLinkError={isVideoLinkError}
            isAudioLinkError={isAudioLinkError}
          />
          {type !== POP_TYPES.FAKE_REPOP && (
            <>
              <UsersSelectionBlock
                users={users}
                checkedIds={checkedIds}
                setCheckedIds={setCheckedIds}
                name="contexts_ids"
                errors={errors}
                titleButton="Pop recipients"
                title="Specify recipients for the Pop"
                isSingleMode={false}
              />
              {retailTags && retailTags.length > 0 && (
                <TagsSelectionBlock
                  checkedTags={checkedTags}
                  setCheckedTags={setCheckedTags}
                  errors={errors}
                  name="tags_context"
                  retailTags={retailTags}
                />
              )}
              <PopNotification
                checked={checkedDefaultPop}
                setChecked={setCheckedDefaultPop}
                register={register}
                resetField={resetField}
                errors={errors}
                title="Enable Pop push notifications"
                pushTitle={watch('push_title')}
                pushMessage={watch('push_body')}
                titleField="push_title"
                messageField="push_body"
                popType={type}
              />
            </>
          )}

          {users.length > 0 &&
            (type === POP_TYPES.RE_POP || type === POP_TYPES.FAKE_REPOP) && (
              <>
                <Divider style={{ marginTop: '10%', marginBottom: '20px' }} />
                <UsersSelectionBlock
                  users={users}
                  checkedIds={checkedRepopIds}
                  setCheckedIds={setCheckedRepopIds}
                  name="repop_contexts_ids"
                  title="Specify the Re-Pop recipient"
                  errors={errors}
                  titleButton="Re-Pop recipient"
                  isSingleMode={type === POP_TYPES.RE_POP}
                />
                <Row style={{ marginTop: '20px' }}>
                  <Box className={classes.inputWrapper}>
                    <TextField
                      {...register('repop_main_btn', {
                        required: 'This field is required   * ',
                      })}
                      id="button-text"
                      className={classes.textField_pop}
                      placeholder="Text"
                      inputProps={{
                        className: classes.input,
                        maxLength: Keys.POP_ACKNBUTTON_SIZE,
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        resetField('repop_main_btn')
                      }}
                      className={classes.iconButton_pop}
                    />
                  </Box>
                  <InputLabel
                    currentError={errors.repop_main_btn}
                    errors={errors}
                    field={watch('repop_main_btn')}
                    name="repop_main_btn"
                    inputSize={Keys.POP_ACKNBUTTON_SIZE}
                    label="Type Re-Pop button text  * "
                  />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                  <Box className={classes.inputWrapper}>
                    <TextField
                      {...register('repop_sec_btn', {
                        required: 'This field is required   * ',
                      })}
                      id="repop_sec_btn-text"
                      className={classes.textField_pop}
                      placeholder="Text"
                      inputProps={{
                        className: classes.input,
                        maxLength: Keys.POP_ACKNBUTTON_SIZE,
                      }}
                    />
                    <DeleteIcon
                      onClick={() => {
                        resetField('repop_sec_btn')
                      }}
                      className={classes.iconButton_pop}
                    />
                  </Box>
                  <InputLabel
                    currentError={errors.repop_sec_btn}
                    errors={errors}
                    field={watch('repop_sec_btn')}
                    name="repop_sec_btn"
                    inputSize={Keys.POP_ACKNBUTTON_SIZE}
                    label="Type Decline button text  * "
                  />
                </Row>
                <PopNotification
                  checked={checkedRepop}
                  setChecked={setCheckedRepop}
                  register={register}
                  resetField={resetField}
                  errors={errors}
                  title="Enable Re-Pop push notifications"
                  pushTitle={watch('repop_push_title')}
                  pushMessage={watch('repop_push_body')}
                  titleField="repop_push_title"
                  messageField="repop_push_body"
                  popType={POP_TYPES.RE_POP}
                />
              </>
            )}
        </form>
      </PopCreationWrapper>
    )
  }
)
