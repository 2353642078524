import React, { memo } from 'react'
import {
  TextField,
  Box,
  InputAdornment,
  Typography,
  Divider,
  IconButton,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import DeleteIcon from '@material-ui/icons/Delete'
import { CustomButton, useStyles } from './style'

export const HeaderModalSearch = memo(
  ({
    setSearchValue,
    searchValue,
    users,
    setCheckedUsers,
    checkedUsers,
    handleSelectAll,
    handleClose,
    isSingleMode,
  }) => {
    const classes = useStyles()

    return (
      <Box className={classes.search_pop_wrapper}>
        <Box style={{ width: '100%' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <IconButton
              onClick={() => handleClose()}
              aria-label="delete"
              style={{
                width: '20px',
                height: '20px',
              }}
            >
              <CloseIcon fontSize="small" width="20px" />
            </IconButton>
            <Typography
              variant="h5"
              style={{
                color: 'gray',
                marginTop: '10px',
                marginBottom: '10px',
                marginLeft: '23%',
              }}
            >
              {!isSingleMode ? 'Select Users' : 'Select User'}
            </Typography>
          </Box>
          <Divider />
        </Box>
        <Divider />
        <TextField
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          className={classes.search_pop}
          placeholder="search the user"
          InputProps={{
            style: {
              paddingLeft: '10px',
              marginTop: '2px',
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: 'gray' }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <DeleteIcon
                  onClick={() => setSearchValue('')}
                  className={classes.IconButton}
                  style={{
                    cursor: 'pointer',
                    fill: 'gray',
                    marginRight: '10px',
                    marginBottom: '2px',
                  }}
                  fontSize="small"
                />
              </InputAdornment>
            ),
          }}
        />
        <Box
          style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
        >
          {!isSingleMode && (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                marginBottom: '20px',
                alignItems: 'center',
              }}
            >
              <Typography variant="h6" style={{ color: 'gray' }}>
                Selected {checkedUsers.length} of {users.length}
              </Typography>
              <CustomButton
                width="max-content"
                style={{ borderRadius: '20px', minWidth: '100px' }}
                onClick={() => handleSelectAll(users, setCheckedUsers)}
              >
                {checkedUsers.length === users.length ? 'Clear' : 'Select All'}
              </CustomButton>
            </Box>
          )}
        </Box>
      </Box>
    )
  }
)
